import { readState } from "@/__main__/app-state.mjs";
import getData, { readData } from "@/__main__/get-data.mjs";
import fetchWikiData from "@/game-destiny2/fetches/fetch-wiki-data.mjs";
import { BungieGetCharacterLoadout } from "@/game-destiny2/models/bungie-character-loadout.mjs";
import * as API from "@/game-destiny2/utils/api.mjs";
import { getBungieApiHeaders } from "@/game-destiny2/utils/get-destiny2-header.mjs";

async function fetchData(_params, _searchParam, state) {
  const requiredPromises = [];

  const {
    lastLoggedInIdByGame: { destiny2: d2profileId },
    loggedInAccounts: { destiny2: d2Accounts },
  } = readState.settings;

  const isSync = !!state?.isSync;
  const account = d2Accounts[d2profileId];
  if (!account?.memberships?.length) return;
  // TODO: prolly need to allow picking memberships?
  const membership = account.memberships[0];

  requiredPromises.push(
    readData(["destiny2", "selectedCharacterId"], { initializeOnce: true }),
    readData(["destiny2", "showInfoModal"], { initializeOnce: true }),
    fetchWikiData(),
    getData(
      API.getCharacterLoadout(membership),
      BungieGetCharacterLoadout,
      ["destiny2", "characterLoadout", d2profileId],
      {
        headers: await getBungieApiHeaders(d2profileId),
        networkBackOffTime: 10 * 1000,
        shouldFetchIfPathExists: isSync,
        shouldSkipDBRead: isSync,
      },
    ),
  );

  return Promise.all(requiredPromises);
}

export default fetchData;
